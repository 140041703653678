
import { mapGetters } from 'vuex'
import filters from "@/mixins/filters";

export default {
  mixins: [filters],
  computed: {
    ...mapGetters({
      settings: 'settings/settings/settings',
      allMarks: 'marks/marks/allMarks',
      banks: 'banks/banks'
    }),
    bank(){
      return this.banks.find(el => el.slug === 't-bank')
    }
  },

}
